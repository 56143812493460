import axios from 'axios';
import router from './router';

let baseURL = '';
if (window.location.hostname.includes('yugainvent.com'))
  baseURL = 'https://chevron-api.yugainvent.com';
else baseURL = 'http://localhost:3004/';

export const bareHTTP = axios.create({
  baseURL: baseURL
});

const http = axios.create({
  baseURL: baseURL
});

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response && error.response.status == 403) {
      router.push({ name: 'Login' });
    } else {
      return Promise.reject(error);
    }
  }
);

export const HTTP = http;
export const backendURL = baseURL;
