<template>
  <v-app>
    <v-app-bar :color="colorBlue" dark dense app>
      <v-img
        class="mr-3"
        src="../assets/logo.png"
        max-height="40"
        max-width="40"
        contain
      ></v-img>
      <v-toolbar-title class="product-title">
        WH Management
      </v-toolbar-title>
      <v-spacer />
      <v-icon @click="logout()">
        mdi-logout
      </v-icon>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <Loading :loading="loadingCount > 0" />
        <br />
        <v-row v-if="!showScanner && !showForm">
          <v-col align="center">
            Welcome <strong>{{ username }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <template v-if="!showScanner && !showForm">
              <v-btn :color="colorBlue" @click="showScanner = true">Scan</v-btn>
              <br />
              <br />

              <div class="text-left">
                <strong>Instructions</strong>
                <ol>
                  <li>Tap "SCAN" button.</li>
                </ol>
                <br />
                <strong>උපදෙස්</strong>
                <ol>
                  <li>"SCAN" බොත්තම ඔබන්න.</li>
                </ol>
              </div>
            </template>
            <template v-if="showScanner">
              <div style="width:300px" v-if="showScanner">
                <v-zxing @decode="onDecode" @loaded="onLoaded" />
                <br />
                <v-btn
                  :color="secondary"
                  v-if="showScanner"
                  @click="showScanner = false"
                  >Back</v-btn
                >
              </div>
              <div class="text-left">
                <strong>Instructions</strong>
                <ol>
                  <li>
                    If there is a notification asking for camera permisison,
                    grant permission.
                  </li>
                  <li>
                    If the camera is not rear camera, select from video source
                    drop down.
                  </li>
                  <li>Point the camera to the data matrix code.</li>
                </ol>
                <br />
                <strong>උපදෙස්</strong>
                <ol>
                  <li>කැමරාව භාවිතා කිරීමට අවසර ඉල්ලන්නේ නම්, එය ලබා දෙන්න.</li>
                  <li>
                    ඔබට දිස්වන්නේ පසුපස කැමරාවෙන් ලැබෙන රූප රාමු නොවේ නම් (
                    මුහුනත කැමරාව පෙනෙන්නේ නම්), Video Source ලැයිස්තුවෙන් පසුපස
                    කැමරාව තෝරන්න.
                  </li>
                  <li>කැමරාව Data Matrix Code එක වෙත යොමු කරන්න.</li>
                </ol>
              </div>
            </template>
            <div v-if="showForm">
              <v-text-field
                label="Product code"
                v-model="data.productCode"
                outlined
                disabled
              />
              <v-text-field
                label="Batch number"
                v-model="data.batchNumber"
                outlined
                disabled
              />
              <v-text-field
                label="Serial number"
                v-model="data.serialNumber"
                outlined
                disabled
              />
              <v-select
                :items="customers"
                label="Customer"
                v-model="data.customer"
                outlined
                single-line
              >
                <template v-slot:item="slotProps">
                  {{ slotProps.item.text }}
                </template>
              </v-select>
              <br />
              <v-btn class="mr-5" :color="colorBlue" @click="saveData"
                >Save</v-btn
              >
              <v-btn
                class="ml-5"
                :color="secondary"
                @click="
                  showScanner = false;
                  showForm = false;
                "
                >Back</v-btn
              >
              <br />
              <br />
              <div class="text-left">
                <strong>Instructions</strong>
                <ol>
                  <li>Select customer from the drop down menu</li>
                  <li>Tap "SAVE" button</li>
                </ol>
                <br />
                <strong>උපදෙස්</strong>
                <ol>
                  <li>
                    Customer ලැයිස්තුවෙන් ඔබට අවශ්‍ය බෙදාහැරීම් ආයතනය තෝරන්න.
                  </li>
                  <li>"SAVE" බොත්තම ඔබන්න.</li>
                </ol>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-snackbar dense v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.msg }}
    </v-snackbar>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        <small>
          Powered by YUGA Invent Private Limited
        </small>
        <div class="build">{{ version }}</div>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
// import UserProfile from '@/components/UserProfile.vue';
import EventBus from '@/eventbus';
import Consts from '@/consts';
import Loading from '@/components/Loading';
import { HTTP } from '../http-common.js';

export default {
  name: 'Home',

  components: { Loading },

  data() {
    return {
      snackbar: {
        timeout: 2000,
        msg: '',
        show: false
      },
      loadingCount: 0,
      colorBlue: Consts.CHEVRON_BLUE,
      colorRed: Consts.CHEVRON_RED,
      showScanner: false,
      showForm: false,
      data: {},
      username: '',
      customers: [],
      version: ''
    };
  },

  mounted() {
    EventBus.$on(Consts.EVENT_NOTIFICATION, e => {
      this.snackbar.msg = e[Consts.EVENT_MESSAGE];
      this.snackbar.color = e[Consts.EVENT_COLOR];
      this.snackbar.show = true;
    });
    this.username = localStorage.getItem('username');
  },

  created: function() {
    if (process.env.COMMIT_HASH) {
      this.version = `build ${process.env.COMMIT_HASH}`;
    }

    // get customers
    HTTP.get('/customer').then(res => {
      const customers = res.data;
      const temp = [];
      customers.forEach(c =>
        temp.push({ text: c.depotName, value: c.depotName })
      );
      this.customers = temp;
    });
  },

  methods: {
    onDecode: function(result) {
      console.log(result);
      const segments = result.split(' ');
      this.showScanner = false;
      this.data['productCode'] = segments[0];
      this.data['batchNumber'] = segments[1];
      this.data['serialNumber'] = segments[2];
      this.showScanner = false;
      this.showForm = true;
    },

    onLoaded: function() {},

    saveData: function() {
      if (!this.data['customer']) {
        alert('Please select a customer');
        return;
      }

      if (!this.data.productCode) {
        alert('Product code is not found in the data matrix code');
        return;
      }

      if (!this.data.batchNumber) {
        alert('Batch number is not found in the data matrix code');
        return;
      }

      if (!this.data.serialNumber) {
        alert('Serial number is not found in the data matrix code');
        return;
      }

      const payload = {
        identifier: `${this.data.productCode}${this.data.batchNumber}${this.data.serialNumber}`,
        data: this.data
      };

      this.loadingCount++;
      HTTP.post(`/itemData`, payload).then(() => {
        this.loadingCount--;

        this.showForm = false;
        this.showScanner = false;

        this.snackbar.msg = 'Data saved';
        this.snackbar.color = 'green';
        this.snackbar.show = true;
      });
    },

    logout: function() {
      this.$store.dispatch('logout');
    }
  },

  computed: {}
};
</script>

<style scoped>
.build {
  font-size: x-small;
}
</style>
