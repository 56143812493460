import Vue from 'vue';
import Vuex from 'vuex';
// import jsonwebtoken from 'jsonwebtoken';
import { HTTP } from '../http-common';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {},
  mutations: {
    //  login: (state, username) => {
    //    state.username = username;
    //  },

    logout: state => {
      state.username = '';
      router.push({ name: 'Login' });
    },

    homeView: () => {
      router.push({ name: 'Home' });
    }
  },
  actions: {
    login: async (context, payload) => {
      localStorage.setItem('token', payload.token);
      localStorage.setItem('username', payload.username);
      HTTP.defaults.headers.common['token'] = payload.token;
    },

    logout: context => {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      context.commit('logout');
    },

    homeView: context => {
      context.commit('homeView');
    }
  },
  modules: {},
  getters: {
    username: state => {
      return state.username;
    }
  }
});
