<template>
  <v-app>
    <v-container fluid fill-height center>
      <v-row>
        <v-col md="4" offset-md="4">
          <v-sheet>
            <v-form ref="form" @submit.prevent="login">
              <v-card class="pa-6" flat>
                <v-card-title primary-title class="justify-center">
                  <img src="../assets/logo.png" />
                  Warehouse Management
                </v-card-title>
                <v-card-text>
                  <!--
                  <v-img :src="companyLogoURL" class="logo mx-auto"></v-img>
                  -->
                  <v-text-field
                    :rules="rules"
                    v-model="user.username"
                    label="Username"
                    autofocus
                  ></v-text-field>
                  <v-text-field
                    :rules="rules"
                    v-model="user.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    label="Password"
                  ></v-text-field>
                  <v-row>
                    <v-alert v-if="error" type="error">
                      {{ error }}
                    </v-alert>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn small color="primary" type="submit">Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-sheet>
        </v-col>
      </v-row>
      <Loading :loading="loading" />
    </v-container>
  </v-app>
</template>

<script>
import { bareHTTP, HTTP } from '@/http-common';
import Loading from '@/components/Loading';
import { uuid } from 'uuidv4';

export default {
  name: 'Login3',

  components: {
    Loading
  },

  data() {
    return {
      loading: false,
      showPassword: false,
      user: {},
      rules: [value => !!value || 'Required.'],
      error: null
    };
  },

  methods: {
    login: async function() {
      if (!this.$refs.form.validate()) return;

      this.error = null;

      if (
        this.user.username.trim().length == 0 ||
        this.user.password.trim().length == 0
      ) {
        this.error = 'Please enter both username and password';
        return;
      }

      try {
        // check for existing local storage deviceId
        let deviceId = localStorage.getItem('device-id');
        if (!deviceId) {
          deviceId = uuid();
          localStorage.setItem('device-id', deviceId);
        }
        HTTP.defaults.headers.common['device-id'] = deviceId;

        this.loading = true;
        const response = await bareHTTP.post(
          'token/login',
          {
            username: this.user.username,
            password: this.user.password
          },
          { headers: { 'device-id': deviceId } }
        );

        await this.$store.dispatch('login', {
          token: response.data.token,
          username: this.user.username
        });
        await this.$store.dispatch('homeView');
        this.loading = false;
      } catch (err) {
        const response = err.response.data;
        if (response.response == 'error') this.error = response.message;
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.logo {
  margin-bottom: 20px;
  width: 50%;
}
</style>
