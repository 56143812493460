<template>
  <v-overlay :value="true" v-if="loading">
    <v-progress-circular indeterminate size="128" :width="3" color="primary">
      <span class="black--text">Loading...</span>
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: 'Loading',

  props: ['loading']
};
</script>

<style></style>
