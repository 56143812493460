import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
// import io from 'socket.io-client';
// import Consts from './consts';
// import EventBus from './eventbus';
import './registerServiceWorker';
import VueZxingScanner from 'vue-zxing-scanner';

// import { HTTP } from './http-common';

Vue.config.productionTip = false;

//import VueMaterial from 'vue-material';
//import 'vue-material/dist/vue-material.min.css';
//import 'vue-material/dist/theme/default.css';

//Vue.use(VueMaterial);
//
Vue.use(VueZxingScanner);

vuetify.preset.theme.dark = true;
vuetify.preset.theme.default = 'dark';

// check for valid auth token
// if (localStorage.getItem('jwt')) {
//   HTTP.defaults.headers.common['x-auth'] = localStorage.getItem('jwt');
//   HTTP.post(
//     `/token/verify`,
//     {},
//     {
//       params: {
//         jwt: localStorage.getItem('jwt')
//       }
//     }
//   )
//     .then(() => {
//       console.log('updating store');
//       store.dispatch('login', localStorage.getItem('jwt'));
//     })
//     .catch(() => {
//       localStorage.removeItem('jwt');
//     });
// }

// store.dispatch('login', localStorage.getItem('jwt'));

const setup = async () => {
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
};
// const socket = io.connect(backendURL);
// socket.on('connect', () => {
//   console.log('Socket connected');
// });

// socket.on(Consts.WEB_SOCKET_NEW_ALERT, payload => {
//   EventBus.$emit(Consts.EVENT_NEW_ALERT, payload);
// });

// socket.on(Consts.WEB_SOCKET_NEW_INSPECTION, payload => {
//   EventBus.$emit(Consts.EVENT_NEW_INSPECTION, payload);
// });

// get application info
// bareHTTP.get(`/init/applicationInfo`).then(data => {
//   store.dispatch('applicationInfo', data.data);
//   window.document.title = data.data.applicationName;
// });
//
setup();
